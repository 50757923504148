import React from "react";
import * as styles from "./index.module.scss";
import { PageLayout } from "@components";

export default function StatusPage() {
	return (
		<PageLayout seoOptions={{type: "PREDEFINED", pageName: "system-status"}}>
			<div className={styles.wrapper}>
				<iframe
					className={styles.iframe}
					src="https://stats.uptimerobot.com/DwPA9TBKAl"
					frameborder="0"
					allowfullscreen
				/>
			</div>
		</PageLayout>
	);
}
